export const getMappedSwiperImages = logos => {
  return logos.map(logo => ({
    url: logo.localFile.publicURL,
    alt: logo.altText,
  }))
}

export const getMappedHubMembers = hubMembers => {
  return hubMembers.map(hubMember => ({
    job: hubMember.job,
    name: hubMember.name,
    image: hubMember.image?.localFile,
  }))
}

export const getMappedMembers = members => {
  return members.map(member => ({
    alt: member.image.altText,
    image: member.image.localFile,
    linkTo: member.link,
  }))
}

export const getMappedServices = services => {
  return services.map(service => ({
    title: service.title,
    text: service.text,
    image: service.image.localFile.publicURL,
  }))
}

export const getMappedNewsCards = data => {
  return data.nodes.map(post => ({
    title: post.title,
    date: post.date,
    content: post.content,
    image: post.featuredImage?.node.localFile,
    slug: post.slug,
  }))
}

export const getMappedEventsCards = data => {
  return data.nodes.map(post => ({
    title: post.title,
    date: post.date,
    content: post.content,
    image: post.featuredImage?.node.localFile,
    slug: post.slug,
    startTime: post.ACF_events.startTime,
    endTime: post.ACF_events.endTime,
    type: post.ACF_events.type,
    location: post.ACF_events.location,
  }))
}
