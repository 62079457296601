import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import { Headline } from "../headline"
import { HubTeam } from "./hub-team"
import { Members } from "./members"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container } from "../../theme"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedHubMembers, getMappedMembers } from "../../core/data.mappers"
import { useTranslation } from "gatsby-plugin-react-i18next"

const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${mediaQuery("lg")} {
    flex-direction: column;
  }
`
const TextWrapper = styled.div`
  margin-left: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;

  ${mediaQuery("xl")} {
    margin-left: 60px;
  }
  ${mediaQuery("lg")} {
    margin-left: 0;
    width: 100%;
  }
`
const Text = styled.div`
  p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.7;
    color: ${colors.gray900};

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  strong {
    font-weight: bold;
  }
`
const Heading = styled.h1`
  margin-bottom: 16px;
  color: ${colors.green100};
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 1.35;
`
const ImageContainer = styled.div`
  width: 100%;
  max-width: 500px;

  ${mediaQuery("lg")} {
    max-width: 500px;
    margin-bottom: 30px;
  }
`

export const AboutUs = ({ ACF }) => {
  const title = ACF.title
  const paragraph = ACF.paragraph
  const image = ACF.image.localFile
  const hubMembers = getMappedHubMembers(ACF.hubTeam)
  const members = getMappedMembers(ACF.members)
const { t } = useTranslation();

  return (
    <>
      <Container>
        <Headline>{t('header.aboutUs')}</Headline>

        <AboutContainer id="about-us">
          <ImageContainer>
            <GatsbyImage alt="" image={getImage(image)} />
          </ImageContainer>

          <TextWrapper>
            <Heading>{title}</Heading>
            <Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: paragraph,
                }}
              ></div>
            </Text>
          </TextWrapper>
        </AboutContainer>

        <HubTeam hubMembers={hubMembers} />
      </Container>
      <Members membersImages={members} />
    </>
  )
}
