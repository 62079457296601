import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { colors, mediaQuery } from "../../theme"
import { Link } from "gatsby"
import { ReactComponent as ArrowRight } from "../../images/arrow-right.svg"
import { useLink } from '../../core/useLink';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { toSlug } from "../../core/utils"

const CardContainer = styled(Link)`
  text-decoration: none;
  max-width: 352px;
  width: 100%;
  min-height: 382px;
  background: white;
  box-shadow: 0px 13px 19px rgba(101, 201, 110, 0.1),
    0px 4px 24px rgba(101, 201, 110, 0.1);
  border-radius: 8px;

  text-decoration: "none";
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  justify-self: center;
  align-self: start;
  height: 100%;
  margin-bottom: 32px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  font-family: Manrope;

  ${mediaQuery("md")} {
    margin: 0 0 48px 0;
  }
`

const Image = styled.div`
  margin-bottom: 20px;
  height: 220px;
  background: linear-gradient(
    180deg,
    ${colors.green100} 0%,
    white 100%
  );
  border-radius: 8px 8px 0px 0px;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
  }
`

const Text = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 20px;

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.726px;
    color: #0d152e;
    margin-bottom: 8px;
  }
  span {
    text-align: left;
    margin-bottom: 16px;
    font-size: 13px;
    color: #81838c;
  }
`

const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 32px;
  box-sizing: border-box;

  p {
    font-size: 16px;
    color: #39b54a;
    line-height: 21px;
    letter-spacing: -0.528px;
    text-overflow: ellipsis;
    transition: color 0.1s ease-in-out, font-size 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      color: #65c96e;
      font-size: 17px;
    }
  }
  svg {
    transition: transform 0.3s ease-in-out;
    fill: #39b54a;

    &:hover {
      cursor: pointer;
      transform: scale(1.3, 1.3);
    }
  }
`

const Paragraph = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  position: relative;
  max-width: 100%;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #81838c;
    width: 100%;
  }
`

export const NewsCard = ({
  content,
  heading,
  image,
  slug,
  date,
  title,
  id,
  hidden,
}) => {
  const { t } = useTranslation();
  const link = `news/${toSlug(title)}`;

  return (
    <CardContainer hidden={hidden} to={link}>
      <div>
        <Image>
          <GatsbyImage image={image} />
        </Image>

        <Text>
          <h5>{heading}</h5>
          <span>{date}</span>
          <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
        </Text>
      </div>
      <CardButton>
        <Link
          to={link}
          style={{
            textDecoration: "none",
          }}
          id={id}
        >
          <p>{t('common.readMore')}</p>
        </Link>

        <Link
          to={`/news/${slug}`}
          style={{
            textDecoration: "none",
            display: hidden ? "none" : "flex",
          }}
          id={id}
        >
          <ArrowRight />
        </Link>
      </CardButton>
    </CardContainer>
  )
}
