import React, { useState } from "react"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import Seo, { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import { Header } from "../components/header";
import { Main } from "../components/main"
import { AboutUs } from "../components/about-us"
import { Services } from "../components/services"
import { News } from "../components/news"
import { Events } from "../components/events"
import { Footer } from "../components/footer"

import { ContactUs } from "../components/contact-us"

const HomeTemplate = ({ data }) => {
  const  { wpPage, wp: { seo}, allWpPost: news , allWpEvent: events} = data;
  const { ACF_homepage } = wpPage;
  const { aboutUs, main, contactUs, services } = ACF_homepage;

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Seo post={wpPage}  />
      <Header newsExists={news.nodes.length > 0} eventsExists={events.nodes.length > 0} />
      <Main ACF={main}/>
      <AboutUs ACF={aboutUs} />
      <Services ACF={services} />
      <News  data={news} />
      <Events data={events} />
      <ContactUs ACF={contactUs} />
      <Footer />

    </SEOContext.Provider>

  );
}

export const query = graphql`
  query($slug: String, $locale: String, $lng: String) {
    allWpPost(filter: {locale: {locale: {eq: $locale }}}) {
        nodes {
          content
          slug
          title
          date(formatString: "YYYY MMMM DD", locale: "en")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    allWpEvent(filter: {locale: {locale: {eq: $locale }}}) {
        nodes {
          content
          slug
          title
          ACF_events {
            endTime
            location
            startTime
            type
          }
          date(formatString: "YYYY MMMM DD", locale: "en")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    wp {
      seo {
        contentTypes {
          post {
            title
            schemaType
            metaRobotsNoindex
            metaDesc
          }
          page {
            metaDesc
            metaRobotsNoindex
            schemaType
            title
          }
        }
        webmaster {
          googleVerify
          yandexVerify
          msVerify
          baiduVerify
        }
        schema {
          companyName
          personName
          companyOrPerson
          wordpressSiteName
          siteUrl
          siteName
          inLanguage
          logo {
            sourceUrl
            mediaItemUrl
            altText
          }
        }
        social {
          facebook {
            url
            defaultImage {
              sourceUrl
              mediaItemUrl
            }
          }
          instagram {
            url
          }
          linkedIn {
            url
          }
          mySpace {
            url
          }
          pinterest {
            url
            metaTag
          }
          twitter {
            username
          }
          wikipedia {
            url
          }
          youTube {
            url
          }
        }
      }
    }
    wpPage(slug: { eq: $slug }, locale: { locale: { eq: $locale } }) {
      title
      content
      ACF_homepage {
        services {
            title
            text
            image {
              localFile {
                publicURL
              }
            }
          }
        contactUs {
            adress
            email
            phoneNumber
            facebook
            linkedin
          }
          aboutUs {
            hubTeam {
              job
              name
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 1024)
                  }
                }
              }
            }
            title
            paragraph
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 1024)
                }
              }
            }
            members {
                image {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(height: 100, placeholder: TRACED_SVG)
                        }
                    }
                }
                link
            }
          }
          services {
            title
            text
            image {
              localFile {
                publicURL
              }
            }
          }
          main {
            title
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 1024)
                }
              }
            }
          }
        } 
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lng } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
    
  }
`

export default HomeTemplate
