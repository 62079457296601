import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import { Headline } from "../headline"
import { ContactForm } from "./contact-form"
import { ReactComponent as MailSvg } from "../../images/mail.svg"
import { ReactComponent as PhoneSvg } from "../../images/phone.svg"
import { ReactComponent as MapSvg } from "../../images/map.svg"
import { ReactComponent as FacebookSvg } from "../../images/facebook.svg"
import { ReactComponent as LinkedinSvg } from "../../images/linkedin.svg"
import { Container } from "../../theme"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  ${mediaQuery("lg")} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Contacts = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 15%;

  ${mediaQuery("lg")} {
    margin-top: 40px;
    width: 100%;
    align-items: flex-start;
    margin-left: 0;
  }
`

const SingleContact = styled.a`
  text-decoration: none;
  margin-bottom: 24px;
  text-align: center;
  transition: color 0.25s ease-in-out;

  svg {
    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.green50};

    svg {
      transform: rotate(15deg) scale(1.1);
    }
  }

  ${mediaQuery("lg")} {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
  }
`

const Text = styled.p`
  margin-top: 16px;
  font-family: Manrope;
  font-size: 16px;
  line-height: 1.375;
  vertical-align: top;
  text-decoration: none;
  color: ${colors.gray900};

  ${mediaQuery("lg")} {
    margin: 0 0 0 20px;
  }
`

const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 32px;
  }
`

export const ContactUs = ({ACF}) => {
  const { t } = useTranslation();
  const {
    email,
    phoneNumber,
    adress,
    facebook,
    linkedin,
  } = ACF;

  const contacts = [
    {
      image: <MapSvg />,
      text: adress,
      href: `https://www.google.com/maps/search/?api=1&query=${adress}`,
    },
    { image: <PhoneSvg />, text: phoneNumber, href: `tel:${phoneNumber}` },
    { image: <MailSvg />, text: email, href: `mailto:${email}` },
    linkedin && { image: <LinkedinSvg />, text: linkedin, href: linkedin },
    facebook && { image: <FacebookSvg />, text: facebook, href: facebook },
  ].filter(Boolean)

  return (
    <Container id="contact-us">
      <Headline>{t('header.contactUs')}</Headline>
      
      <ContactContainer>
        <ContactForm />
        <Contacts>
          {contacts.map(({ image, text, href }) => (
            <SingleContact target="_blank" href={href}>
              <SvgContainer>{image}</SvgContainer>

              <Text>{text}</Text>
            </SingleContact>
          ))}
        </Contacts>
      </ContactContainer>
    </Container>
  )
}
