import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { colors, mediaQuery } from "../../../theme"
import { rem } from "polished"
import { Input } from "./input"
import { useForm } from "react-hook-form"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: ${rem(32)};
  }

  background: ${colors.white};
  box-shadow: 0px 13px 19px rgba(101, 201, 110, 0.2),
    0px 4px 24px rgba(101, 201, 110, 0.3);
  padding: 52px 50px;
  width: 517px;
  ${mediaQuery("lg")} {
    width: unset;
  }
`

const Button = styled.button`
  background: ${colors.green100};
  padding: 10px 16px;
  border: none;
  color: ${colors.white};

  small {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" })

  console.log(errors);

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
    }
  }

  const handleOnSubmit = (data, e) => {
    e.target.reset()
    setServerState({
      submitting: true,
      status: { msg: t('form.submitting') },
    })
    axios({
      method: "post",
      url: "https://getform.io/f/d7f79235-e7a0-4ed7-9660-82b69f3ecaa0",
      data,
    })
      .then(r => {
        handleServerResponse(true, t('form.success'))
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error)
      })
  }

  const { t } = useTranslation();

    return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <Input
        {...register("email", { required: true })}
        name="email"
        placeholder={t('form.email')}
        type="email"
        error={errors["email"]}
      />

      <Input
        {...register("name", { required: true })}
        name={"name"}
        placeholder={t('form.name')}
        error={errors["name"]}
      />

      <Input
        {...register("message", { required: true })}
        iconName="chat"
        name="message"
        type="textarea"
        placeholder={t('form.message')}
        error={errors["message"]}
      />

      <Button type="submit">
        <small>{serverState.status?.msg ?? t('form.send')}</small>
      </Button>
    </Form>
  )
}
