import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swipe } from "./swipe"
import { Container } from "../../theme"
import { graphql, useStaticQuery } from "gatsby"

const LargeText = styled.p`
  color: ${colors.white};
  font-weight: 800;
  font-size: 60px;
  line-height: 1.3;
  font-family: Manrope;
  text-align: center;
  filter: drop-shadow(0px 0px 20px rgba(71, 74, 87, 0.5));

  ${mediaQuery("lg")} {
    font-size: 48px;
  }
  ${mediaQuery("sm")} {
    font-size: 34px;
  }

  ${mediaQuery("xl_1500", "min")} {
    font-size: 100px;
  }
`
const SmallText = styled.p`
  font-size: 20px;
  line-height: 1.3;
  color: ${colors.white};
  margin: 0;
  max-width: 400px;
  text-align: center;
  font-family: Manrope;
  filter: drop-shadow(0px 0px 20px rgba(71, 74, 87, 0.5));

  ${mediaQuery("sm")} {
    font-size: 16px;
  }
  ${mediaQuery("xl_1500", "min")} {
    max-width: 500px;
    font-size: 30px;
  }
`
const MainWrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  .hero-image {
    height: 55vh;
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      background: linear-gradient(
        263.28deg,
        rgba(71, 74, 87, 0.75) 0%,
        rgba(71, 74, 87, 0.75) 0.01%,
        rgba(101, 201, 110, 0.375) 99.75%
      );
    }

    ${mediaQuery("xl")} {
      height: 360px;
    }

    ${mediaQuery("xl_1450", "min")} {
      height: 70vh;
    }
  }
`
const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Main = ({ ACF }) => {
  console.log(ACF);
  const title = ACF.title
  const text = ACF.text
  const image = ACF.image.localFile


  return (
    <MainWrapper>
      <ImageContainer>
        <TextWrapper>
          <LargeText>{title}</LargeText>
          <SmallText>{text} </SmallText>
        </TextWrapper>
        <GatsbyImage alt="" className="hero-image" image={getImage(image)} />
      </ImageContainer>
      <Container>
        <Swipe />
      </Container>
    </MainWrapper>
  )
}
