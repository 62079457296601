import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import { Headline } from "../headline"
import { Container } from "../../theme"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedServices } from "../../core/data.mappers"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Service = styled.div`
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;

  ${mediaQuery("lg")} {
    padding-right: 0;
  }
`
const Title = styled.p`
  color: ${colors.black};
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 8px;
`
const Text = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.625;
  color: ${colors.gray900};
`
const TextWrap = styled.div`
  width: 428px;

  ${mediaQuery("xxl")} {
    width: 380px;
  }
  ${mediaQuery("xl_1350")} {
    width: 320px;
  }
  ${mediaQuery("xl")} {
    width: auto;
  }
`
const Image = styled.div`
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 100px;
  box-shadow: 0px 30px 40px rgba(101, 201, 110, 0.2),
    0px 4px 24px rgba(101, 201, 110, 0.3);
  background: #f4f5f7;
  flex-shrink: 0;
`

export const Services = ({ ACF }) => {
  const services = getMappedServices(ACF);
  const { t } = useTranslation();

  return (
    <Container id="services">
      <Headline>{t('header.services')}</Headline>

      <ServicesContainer>
        {services.map((service, index) => (
          <Service key={index}>
            <Image>
              <img src={service.image} alt={service.text} />
            </Image>

            <TextWrap>
              <Title>{`${index + 1}. ${service.title}`}</Title>
              <Text>{service.text}</Text>
            </TextWrap>
          </Service>
        ))}
      </ServicesContainer>
    </Container>
  )
}
