import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedSwiperImages } from "../../../core/data.mappers"
import "swiper/swiper.scss"

SwiperCore.use([Autoplay])

const GrayLine = styled.div`
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
  width: 80%;
  margin: 0 auto;
`

const LogosSection = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 96px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const Swipe = () => {
  const [slidesInView] = useState(6)
  const {
    wpPage: { ACF_homepage },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          swiper {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const logos = getMappedSwiperImages(ACF_homepage.swiper)

  useEffect(() => {
    if (window) {
      window.dispatchEvent(new Event("resize"))
    }
  }, [])

  return (
    <>
      <GrayLine />
      <LogosSection>
        <Swiper
          autoplay={{ delay: 2000 }}
          spaceBetween={100}
          slidesPerView={slidesInView}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {logos.map(logo => (
            <SwiperSlide key={logo.url}>
              <ImageContainer>
                <img src={logo.url} alt={logo.alt} />
              </ImageContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </LogosSection>
      <GrayLine />
    </>
  )
}
