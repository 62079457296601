import React from "react"
import styled, { css } from "styled-components"
import { colors } from "../../theme"

const ButtonContainer = styled.button`
  box-sizing: border-box;
  padding: 10px 24px;
  background: #39B54A;
  color: ${colors.white};
  border: ${colors.white};
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  width: max-content;

  letter-spacing: -0.528px;
  color: ${colors.white};
  transition: opacity 0.3s ease-in-out;

  &:hover {
  opacity: 0.8;
  }

  &:disabled {
    display: none;
  }
`

export const Button = ({ children, onClick, disabled }) => {
  return (
    <ButtonContainer  onClick={onClick} disabled={disabled}>
      {children}
    </ButtonContainer>
  )
}
