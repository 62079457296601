import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../../theme"
import { Container } from "../../../theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

const MembersContainer = styled.div`
  width: 100%;
  background: ${colors.black100};
  position: relative;
  overflow: hidden;
  margin-top: 64px;
  padding-bottom: 96px;
`
const SmallCircle = styled.div`
  width: 435px;
  height: 435px;
  border-radius: 50%;
  background: rgba(101, 201, 110, 0.5);
  background-repeat: no-repeat;
  position: absolute;
  left: -100px;
  top: -100px;

  ${mediaQuery("sm")} {
    width: 350px;
    height: 350px;
  }
`

const BigCircle = styled.div`
  width: 640px;
  height: 640px;
  border-radius: 50%;
  background: rgba(101, 201, 110, 0.5);
  position: absolute;
  right: -150px;
  bottom: -150px;

  ${mediaQuery("sm")} {
    width: 340px;
    height: 340px;
  }
`
const Heading = styled.h1`
  color: ${colors.green100};
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 1.3;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 32px;
`

const Rectangle = styled.a`
  width: 172px;
  height: 96px;
  background: ${colors.gray50};
  box-shadow: 0px 30px 40px rgba(101, 201, 110, 0.2);
  border-radius: 16px;
  margin: 24px 10px 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  box-sizing: border-box;
  transition: transform ease-in-out 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
  }
`
const RectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const Members = ({ membersImages }) => {
  const { t } = useTranslation();
  return (
    <MembersContainer id="members">
      <SmallCircle />
      <BigCircle />
      <Container>
        <Heading>{t('header.members')}</Heading>
        <RectContainer>
          {membersImages.map(membersImage => {
            return (
              <Rectangle
                href={membersImage.linkTo}
                target="_blank"
                key={membersImage.alt}
              >
                <GatsbyImage
                  alt={membersImage.alt}
                  image={getImage(membersImage.image)}
                />
              </Rectangle>
            )
          })}
        </RectContainer>
      </Container>
    </MembersContainer>
  )
}
