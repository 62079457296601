import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import { ReactComponent as HeadlineSvg } from "../../images/headline.svg"

const HeadlineWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 48px 0 48px;

  ${mediaQuery("sm")} {
    margin: 48px 0 48px;
  }
`
const Text = styled.h1`
  position: absolute;
  left: 100px;
  font-family: Manrope;
  font-weight: 800;
  font-size: 48px;
  line-height: 1.3;
  color: ${colors.black100};

  ${mediaQuery("sm")} {
    font-size: 40px;
  }
  ${mediaQuery("sm_360")} {
    font-size: 34px;
    left: 90px;
  }
`

export const Headline = ({ children }) => {
  return (
    <HeadlineWrapper>
      <HeadlineSvg />
      <Text>{children}</Text>
    </HeadlineWrapper>
  )
}
