import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import { colors, mediaQuery } from "../../../../theme"
import { rem } from "polished"

const InputContainer = styled.div`
  padding: ${rem(16 + 1)};
  line-height: 1;
  display: flex;
  align-items: center;
  background-color: ${colors.gray};
  box-sizing: border-box;
  border: 1px solid ${colors.gray50};

  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}

  ${mediaQuery("sm")} {
    padding: ${rem(14)};

    ::placeholder {
      font-size: ${rem(14)};
    }

    svg {
      min-width: 20px;
    }
  }
`

const InputStyled = styled.input`
  border: none;
  background-color: transparent;
  height: 100%;
  outline: none;
  margin-left: ${rem(8)};
  line-height: 1;
  color: ${colors.gray900};
  width: 100%;
  font-family: Manrope;

  &::placeholder {
    color: ${colors.gray900};
  }
`

export const Input = forwardRef(
  ({ placeholder, type = "text", name, error, ...restProps }, ref) => {
    const isTextarea = type === "textarea"
    const textareaProps = isTextarea ? { as: "textarea", rows: 4 } : {}

    return (
      <>
        <InputContainer isTextarea={isTextarea} error={error}>
          <InputStyled
            ref={ref}
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            {...textareaProps}
            {...restProps}
          />
        </InputContainer>
      </>
    )
  }
)
