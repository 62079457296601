import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { mediaQuery, Container } from "../../theme"
import { Headline } from "../headline"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedEventsCards } from "../../core/data.mappers"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Button } from "../button"
import { EventCard } from "./eventCard"

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  ${mediaQuery("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${mediaQuery("lg")} {
    grid-template-columns: 1fr;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
`

const Section = styled.section`
  margin-top: 48px;
`

export const Events = ({ data }) => {
  const eventsCards = getMappedEventsCards(data)
  const [count, setCount] = useState(3)

  useEffect(() => {
    if (count === 3) {
      return
    }

    count > 3 && scrollTo(`#events-card-${count - 3}`, "start")
  }, [count])

  return !!eventsCards.length ? (
    <Container id="events">
      <Section>
        <Headline>Events</Headline>

        <CardsContainer>
          {eventsCards.reverse().map((card, index) => (
            <EventCard
              card={card}
              id={`events-card-${index + 1}`}
              hidden={index + 1 > count}
            />
          ))}
        </CardsContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              setCount(count + 3)
            }}
            disabled={count >= eventsCards.length}
          >
            Show more
          </Button>
        </ButtonContainer>
      </Section>
    </Container>
  ) : null
}
