import React from "react"
import styled from "styled-components"
import { colors } from "../../../theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Member = styled.div`
  width: 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  gap: 48px;
`

const Heading = styled.h1`
  color: ${colors.green100};
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 1.35;
  text-align: center;
`

const Image = styled.div`
  margin-bottom: 12px;

  .gatsby-image-wrapper {
    border: 1px solid ${colors.white};
    border-radius: 50%;
    height: 180px;
    object-fit: cover;
    width: 180px;
    box-shadow: 0px 0px 24px ${colors.green100};
    z-index: 0;
  }
`

const Name = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.55;
  margin: 12px 0;
  color: ${colors.black100};
`

const Job = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 1.7;
  margin: 0;
`
export const HubTeam = ({ hubMembers }) => {
  const { t } = useTranslation()
  return (
    <>
      <Heading>{t("header.team")}</Heading>
      <Container>
        {hubMembers.map(member => (
          <Member key={member.name}>
            <Image>
              <GatsbyImage alt="" image={getImage(member.image)} />
            </Image>
            <Name>{member.name}</Name>
            <Job>{member.job}</Job>
          </Member>
        ))}
      </Container>
    </>
  )
}
